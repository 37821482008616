.waitlist-form {
  &__submit {
    vertical-align: middle;
  }
  &__email-promotion {
    margin-bottom: 20px;
    &-label {
      text-transform: none;
    }
  }
  &__disclaimer {
    margin-top: 15px;
    margin-bottom: 0;
    a {
      text-decoration: none;
    }
  }
}
